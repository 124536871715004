import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  myStorage = window.localStorage
  setIdentity(identity: any) {
    this.myStorage.setItem('identity', JSON.stringify(identity))
  }

  removeIdentity() {
    this.myStorage.removeItem('identity')
  }

  getIdentity(): any {
    const identity: string = this.myStorage.getItem('identity')
    return JSON.parse(identity)
  }

  setUser(user: any) {
    this.myStorage.setItem('user', JSON.stringify(user))
  }

  getUser(): any {
    const user: string = this.myStorage.getItem('user')
    return JSON.parse(user)
  }

  getUserId(): any {
    const identity: string = this.myStorage.getItem('identity')
    if (identity) {
      return JSON.parse(identity).userId
    }
    return null
  }

  getToken(): any {
    const identity: string = this.myStorage.getItem('identity')
    if (identity) {
      const token = JSON.parse(identity).accessToken
      if (token) {
        return token
      }
    }
    return null
  }

  isAuthenticated(): boolean {
    const identity: string = this.myStorage.getItem('identity')
    if (identity) {
      return true
    }
    return false
  }

  getUserName() {
    const identity: string = this.myStorage.getItem('identity')
    if (identity) {
      const userName = JSON.parse(identity).userName
      if (userName) {
        return userName
      }
    }
    return null
  }

  getEnvironment() {
    const identity: string = this.myStorage.getItem('identity')
    if (identity) {
      const env = JSON.parse(identity).env
      if (env) {
        return env
      }
    }
    return null
  }

  setChangePasswordToken() {
    sessionStorage.setItem('modal', this.getUserId())
  }
  isChangePasswordActive(): boolean {
    if (sessionStorage.getItem('modal'))
      return sessionStorage.getItem('modal') == this.getUserId();
    else
      return false;
  }
  removeChangePasswordToken() {
    sessionStorage.removeItem('modal');
  }
}
