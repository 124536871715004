import { StorageService } from './../storage.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private storageService: StorageService) { }

  hasPermission(permission: string): boolean {
    const identity = this.storageService.getIdentity();
    if (identity) {
      const permissions = identity.permissions;
      if (permissions) {
        return (',' + permissions + ',').indexOf(',' + permission + ',') !== -1;
      }
    }
    return false;
  }

  isTeamLead(): boolean {
     const identity = this.storageService.getIdentity();
    if (identity) {      
      return identity.isTeamLeader;
    }
      
    return false;
  }

  getUserId() {

    return this.storageService.getUserId();
  }

  getUserName(){
    return this.storageService.getUserName();
  }

}
