export class SelectedRes {
  time: number;
  count: number;
  orderDates: any;
  selected: any;
  pointOfLift = 3;
  videoTime = 5;
  pointOfLifts = '3';
  videoTimes = '5';
  
  constructor() {
    this.time = new Date().getTime();
    this.selected = [];
  }

}