import {
  RouteReuseStrategy,
  DetachedRouteHandle,
  ActivatedRouteSnapshot,
} from '@angular/router';

export class CacheRouteReuseStrategy implements RouteReuseStrategy {

  lastVisitedUrl = '';
  storedRouteHandles = new Map<string, DetachedRouteHandle>();
  allowRetriveCache = {
    'home': true
  };

  shouldDetach(route: ActivatedRouteSnapshot): boolean {

    const name = this.getConfiguredUrl(route);
    this.lastVisitedUrl = name;
    console.log('shouldDetach() ========> ' + name);
    if (this.allowRetriveCache.hasOwnProperty(name)) {
      return true;
    }
    return false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.storedRouteHandles.set(this.getConfiguredUrl(route), handle);
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {

    const name = this.getConfiguredUrl(route);
    if (this.allowRetriveCache[name]) {
      return this.storedRouteHandles.has(this.getConfiguredUrl(route));
    }

    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return this.storedRouteHandles.get(
      this.getConfiguredUrl(route)
    )
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  private getConfiguredUrl(route: ActivatedRouteSnapshot): string {

    return route.pathFromRoot
      .filter((v) => v.routeConfig && v.routeConfig.path !== '')
      .map((v) => v.routeConfig.path)
      .join('/');
  }

}
