import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  public showSpinner = false;
  show() {
    setTimeout(() => {
      this.showSpinner = true;
    });
  }

  hide() {
    setTimeout(() => {
      this.showSpinner = false;
    });
  }

  showMessage(snackBar: MatSnackBar, message: string) {
    snackBar.open(message, '', {
      duration: 2000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    });
  }
}
