import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectionExportComponent } from './selection-export.component';
import { NzCardModule, NzCheckboxModule, NzFormModule, NzGridModule, NzDividerModule, NzButtonModule, NzSelectModule } from 'ng-zorro-antd';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NzFormModule,
    ReactiveFormsModule,
    NzCardModule,
    NzCheckboxModule,
    NzGridModule,
    NzDividerModule,
    NzButtonModule,
    NzSelectModule
  ],
  declarations: [SelectionExportComponent],
  exports: [SelectionExportComponent],
  entryComponents: [SelectionExportComponent],
})

export class SelectionExportModule { }