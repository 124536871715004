import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableColumnSelectionComponent } from './table-column-selection.component';
import {
  NzCardModule,
  NzCheckboxModule,
  NzButtonModule,
  NzGridModule,
} from 'ng-zorro-antd';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NzCardModule,
    NzCheckboxModule,
    NzButtonModule,
    NzGridModule,
  ],
  declarations: [TableColumnSelectionComponent],
  exports: [TableColumnSelectionComponent],
  entryComponents: [TableColumnSelectionComponent],
})
export class TableColumnSelectionModule {}
