import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router'
import { Observable, of } from 'rxjs'
import { PermissionService } from 'src/app/services/auth/permission.service'
import { StorageService } from './storage.service'

@Injectable({
  providedIn: 'root',
})
export class LoginGuardService implements CanActivate {
  constructor(
    private storageService: StorageService,
    private permissionService: PermissionService,
    private router: Router,
    private http: HttpClient,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    if (
      this.storageService.isAuthenticated() &&
      this.permissionService.hasPermission('ims:sales_login') && !this.storageService.isChangePasswordActive()
      
    ) {
      this.router.navigate(['/home'])
      return of(false)
    } else {
      return of(true)
    }
  }
}
