import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  matSidenav: MatSidenav;
  public showSidenavToggle = true;

  toggleState() {
    if (this.matSidenav) {
      this.matSidenav.toggle();
    }
  }

  public setMatSidenav(matSidenav: MatSidenav): void {
    this.matSidenav = matSidenav;
  }
}
