import { SpinnerService } from './../spinner.service';
import { StorageService } from './../storage.service';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SigninService {
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private spinnerService: SpinnerService,
    private snackBar: MatSnackBar
  ) {}

  doLogin(cridential: any): Observable<any> {
    return this.http.post(`${environment.API_URL}/signin`, cridential).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.spinnerService.showMessage(this.snackBar, 'Bad Cridential');
        }
        return throwError(error.error);
      })
    );
  }

  doLogout() {
    this.storageService.removeIdentity();
  }

  checkPassword(password: any) {
    const data = {
      oldPassword: password,
    };
    return this.http.post(`${environment.API_URL}/user/checkPassword`, data);
  }
}
