import { UploadFile } from 'ng-zorro-antd/upload';
import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { FileService } from 'src/app/services/files/file.service';
import { environment } from 'src/environments/environment';
import { OrderService } from 'src/app/services/order.service';
import { Observable, Observer } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';

function getBase64(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
})
export class FileUploadComponent implements OnInit {
  displayStyle = 'inline';
  @Input() data?: any;
  properties: any;
  isSpinning = false;
  files: any;
  nzAction: any;
  property: any;
  type: any;
  icons = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    showDownloadIcon: true,
  };
  previewImage: string | undefined = '';
  previewVisible = false;

  constructor(
    private el: ElementRef,
    private fileService: FileService,
    private orderService: OrderService,
    private messageService: NzMessageService
  ) { }

  ngOnInit() {
    this.findOrderProperty();
  }

  beforeUpload = (file: any, _fileList: any[]) => {
    return new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/gif' ||
        file.type === 'image/bmp';
      if (!isJpgOrPng) {
        this.messageService.error('You can only upload image file!');
        observer.complete();
        return;
      }
      observer.next(isJpgOrPng);
      observer.complete();
    });
  };

  handleRemove = (file: any, _fileList: any[]) => {
    if (file.id) {
      this.files = this.files.filter((fileTemp) => fileTemp.id !== file.id);
      this.fileService.deleteOrderImage(file).subscribe((response: any) => {
        console.log('Order image is deleted successfully');
      });
    } else {
      this.files = this.files.filter((fileTemp) => fileTemp.name !== file.name);
    }
  };

  stateChange(event: any) {
    if (event.file.status === 'error') {
      console.log('Error..........');
    }
  }

  ngAfterViewInit() {
    const upload = this.el.nativeElement.querySelector('nz-upload');
    const container = this.el.nativeElement.querySelector('#filter-container');
    upload.children[0].style.padding = '0 8px';
    container.append(upload.children[0]);

  }

  findOrderProperty() {
    this.orderService.findOrderProperty(this.data.id).subscribe((data) => {
      this.properties = data;
    });
  }

  findOrderImage() {
    this.isSpinning = true;
    this.fileService
      .findAllImageByProperty(this.data.id, this.property, this.type)
      .subscribe((data: any) => {
        this.files = [];
        data.forEach((image) => {
          const name = image.images.replace(/\.([a-zA-Z]+)$/, `_200x200.$1`);

          this.files.push({
            id: image.id,
            name: image.fileName,
            status: 'done',
            thumbUrl: `http://res-bbmedia-in.s3-website.ap-south-1.amazonaws.com/${name}`,
            url: `https://res-bbmedia-in.s3.ap-south-1.amazonaws.com/${image.images}`,
          });
        });
        this.isSpinning = false;
      });
  }

  showUploadIcon() {
    if (this.property && this.type) {
      this.findOrderImage();
      this.nzAction = `${environment.API_URL}/file/order/${this.data.id}/${this.property}/${this.type}`;
    }
  }

  handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };
}
